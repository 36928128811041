import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import ReviewPage from './_components/ReviewPage'

const Index = ({ data: { articles, categories, props } }) => {
	props.meta.canonical = `${process.env.GATSBY_SITE_HOST}/${props.slug}/`

	return <ReviewPage {...props} articles={articles} categories={categories} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	{
		articles(limit: 6) {
			canonical
			displayModified
			title
		}
		categories(limit: 6, sort: { displayModified: DESC }) {
			canonical
			subtitle
			cardDescription
		}
		props: pageModel(slug: "reviews") {
			slug
			meta {
				robots
				description
				title
			}
			... on ReviewsPage {
				title
				subtitle
				content
				firstCreated
				headline
				backgroundImage
				type
				thumbnail {
					path
					caption
				}
				coreOfferings {
					canonical
					title
					description
				}
				methodology {
					description
					methodologyDescriptionPoints
					title
				}
				additionalTools {
					title
					tools {
						title
						description
						url
						cta
					}
				}
				testimonials {
					title
					subTitle
					background
					listTestimonials {
						title
						clientName
						industry
						content
					}
				}
				priceQuotes {
					cta
					description
					image
					path
					title
				}
			}
		}
	}
`
